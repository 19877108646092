import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductApi } from '../../../services/Redux/Reducer/AllProductSlice';
import { SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';

const useHomePageHook = (props) => {
    //***********************************Home First Section *******************************************/

    const productSliderRef = useRef()
    const productNext = () => productSliderRef?.current?.slickNext();
    const productPrevious = () => productSliderRef?.current?.slickPrev();

    //***********************************Home Second Section *******************************************/
    const sliderRef = useRef();
    const next = () => sliderRef?.current?.slickNext();
    const previous = () => sliderRef?.current?.slickPrev();

    const creamData = useSelector((state) => state?.AllProductSlice?.creamData);
    const topSellers = useSelector((state) => state?.AllProductSlice?.topSellers);
    const { loading } = useSelector((state) => state?.LoginSlice);
    const dispatch = useDispatch();

    const CUSTOMER_RATING = [
        {
            rating: 5,
            product_name: "Wellness Essentials",
            message: `I have been taking these “new to me” Abeytu Wellness Essentials Capsules 2 at a time, every 4 hours to help with “discomfort” and aches in my back and ribs from a hard fall……..they have saved my fanny big time. Reading the specific ingredients is a Joy! OF COURSE who wouldn’t love to have Organic Blueberry and Shilajit in their body? THANK YOU Abeytu for another incredible plant based product.`,
            name: " Judy K."
        },
        {
            rating: 5,
            product_name: "Detox regime … ",
            message: `Had a 66 yr old lady, diabetic, uses a cane to walk around.
            I saw her in August & put her on InsuLess along with an extract for glucose support. Saw her 3 weeks ago for a follow up. She said, "Just give me the InsuLess, I haven’t taken any metformin and Jardiance in the two months and my blood sugar is good. It is 90s to 115 coming from an average of 150s to 170s."`,
            name: "Dr Jermaine Nicholas"
        },
        {
            rating: 5,
            product_name: "Detox regime … ",
            message: `Had a 66 yr old lady, diabetic, uses a cane to walk around.
            I saw her in August & put her on InsuLess along with an extract for glucose support. Saw her 3 weeks ago for a follow up. She said, "Just give me the InsuLess, I haven’t taken any metformin and Jardiance in the two months and my blood sugar is good. It is 90s to 115 coming from an average of 150s to 170s."`,
            name: "Dr Jermaine Nicholas"
        },
        {
            rating: 5,
            product_name: "Detox regime … ",
            message: `I tried InsuLess in Sport and was very impressed how high my Energy was and how fast i recoverd the next day ! It helpes me also in my daily Detox regime … very good product thanks 🙏🏼 `,
            name: " Kevin R. -Germany "
        },
        {
            rating: 5,
            product_name: "tinctures … ",
            message: `AMAZING, almost instant results ! I knew my lip was getting ready to have an issue, that tingling, bothersome feeling that preceeds it. Plus I had the overall feeling of discomfort/tiredness, and more. Knowing these Abeytu Naturals tinctures, I grabbed the new Insuless bottle and just dabbed some on my lower lip. I really didn’t give it anymore thought - which meant it wasn’t bothering me anymore. And now 2 days later, realizing no issue materialized. My lip is kissable. :*)
            My name is Judy, and I’ve used these tinctures for 2 1/2 years now. Which means, I am sooo very pleased with them, and will be a forever customer.`,
            name: "Judy K"
        },
        {
            rating: 5,
            product_name: "Abeytu´&reg;® Naturals CV Well Immunity Booster",
            message: `I have been using the CV Well Tincture for several months due to its recognizable micronutrient contents.
             As a physician, I highly recommend this product as an all in one solution to immune system homeostasis and prevention. 
             Home run with this one. I am happy to throw my support behind this product. Congrats!`,
            name: "Sterling Simpson MSc, MD"
        },
        {
            rating: 5,
            message: `I love these products. My skin looks 10 years younger. My pores are almost all closed.T`,
            name: "Tina Nelson"
        },
        {
            rating: 5,
            message: `Goal was to get off 2 prescription medications. Goal met for one within 4 weeks!`,
            name: "Teresa LeBlanc"
        },
        {
            rating: 5,
            message: `Excellent product, works well...felt I had a cold coming on, dosed up and gone by that afternoon !`,
            name: "Ellsa R"
        },
        {
            rating: 5,
            message: `I have been on this for almost a month. I have noticed increased energy and overall a feeling of well being.
             I have been around a lot of sick people lately and I haven't gotten sick so it definitely has boosted my immune system! Thank you.`,
            name: "Lorye McLeod"
        },
        {
            product_name: "Abeytu´&reg;® Naturals CV Well Immunity Booster",
            rating: 5,
            message: `Since I’ve started taking the tincture, I have more energy, less body aches/stiffness after exercising and more stamina.
             There isn’t anything else that I’ve done differently. I believe it’s the tincture that’s made a positive difference in me.`,
            name: "j. almas"
        },
        {
            product_name: "Abeytu´&reg;® Naturals CV Well Immunity Booster",
            rating: 5,
            message: `I have been taking the immunity booster for quite some time now and have to honestly say it has kept me from calling 
            out sick from work as much as I used to.c`,
            name: "Marilyn Rios"
        },
        {
            product_name: "",
            rating: 5,
            message: `When I got introduced to this immune booster I was very very sick no one knows why but this immune booster probably 
            saved my life literally I’m still taking itR`,
            name: "creta johnson"
        },
        {
            rating: 5,
            message: `I am really impressed by the results I am seeing within 1 month of usage. It's MAGIC!`,
            name: "Belinda Connor"
        },
        {
            product_name: "Abeytu´&reg;® Naturals CV Well Immunity Booster",
            rating: 5,
            message: `Your amazing product, CV Well Immunity Booster, resolved a persistent tooth infection that nothing else touched. 
            And while that was enough to draw me to your company and its products, your customer service is very responsive,
             and I love the informative emails you send. Thank you and keep up the good work!`,
            name: "R.Delighted"
        },
        {
            rating: 5,
            message: `This is forth month and hair seems to be growing.`,
            name: "Lee-AnnSo"
        },
        {
            product_name: "Abeytu´&reg;® Naturals CV Well Immunity Booster",
            rating: 5,
            message: `I have been taking supplements and superfoods for year and years.Since 2015, I have had a myriad of health issues 
            that have been considered life threatening
            The doctors haven't been able to assist and I have been taking some medications to slow down any further deteriorations and complications.
            Since taking the CV Well Immuno-Boosting Tincture, my lungs are clearing up and I am beginning to take fuller breaths. 
            I can climb the stairs at home without stopping to catch my breath half way.
            If this is the efficacy of this brilliant and Divinely created product after only 2 weeks,
             I am super excited to experience further improvement to my health.`,
            name: "HELEN SCHEINERWOW"
        },
        {
            rating: 5,
            message: `Turned my world around, from chest pain, sleeplessness and nervousness, to pain free, and sleeping well..
             Thank you for this natural medicine.`,
            name: "Jenny Louise van van Pellekaan"
        },
        {
            rating: 5,
            message: `Ive never been one to write a review for a product, until now. This one definitely deserves a 5 star rating. 
            I started using this tincture twice a day, 13 days ago and Im already feeling better. My anxiety is much lower, 
            Im sleeping so much better, I had some previous gut issues and those have been relieved. Im seeing some good things on the outside as well.
             Its true that if you feel good on the inside, youll look good on the outside. This is an amazing product and Id highly recommend it to everyone.
              Thank you for creating this "liquid gold.`,
            name: "Robyn Rosenbaum"
        },
        {
            rating: 5,
            message: `I started this product 1.5 months ago, before I started it, my hot flashes were miserable and of course wasn't sleeping.
             9 days after starting this the hot flashes were gone and my sleep is amazing. I have more energy then before and would highly
             recommend this product to anyone looking to "feel better" This is the real deal. Thank you for such an amazing product.`,
            name: "Tracee Batt"
        },
    ];

    useEffect(() => {
        if (creamData?.length === 0) {
            dispatch(getAllProductApi("creamproduct"));
        }
        if (topSellers?.length === 0) {
            dispatch(getAllProductApi("topSellers"));
        }

    }, [])

    /**************************************** HOmepage 3rd Section*******************************************************/

    const [successMesssage, setSuccessMessage] = useState(null);

    async function _sendGridContactForm(data, actions) {
        dispatch(SignUpFormApi({
            firstName: data?.name,
            lastName: data?.lastname,
            email: data?.email,
        },
            'leadUser',
            (data) => {
                if (data) {
                    setSuccessMessage('Congratulations, You have successfully joined our Abeytu´&reg; community.');
                    actions?.resetForm({
                        email: "", name: "", lastname: "", birth_month: ""
                    });
                    setTimeout(() => setSuccessMessage(null), 3000);
                }
            }
        ));
    }

    return {
        loading,
        CUSTOMER_RATING,
        //*******Home1 * */
        productNext,
        productPrevious,
        productSliderRef,
        //*********Home 2 */
        creamData,
        topSellers,
        sliderRef,
        next,
        previous,
        /*home 3rd*/
        _sendGridContactForm,
        successMesssage
    }
}

export default useHomePageHook;